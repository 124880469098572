import tableMutations from "@/store/templates/table/mutations";
import { isNil } from "lodash";
import Vue from "vue";

export default {
  updateCountryPrice(state, newData) {
    newData.entities.entity[newData.result].countries.forEach(country => {
      state.companySubjectCountry[country] =
        newData.entities.companySubjectCountry[country];
      if (isNil(state.companySubjectCountry[country])) {
        Vue.set(
          state.companySubjectCountry,
          country,
          newData.entities.companySubjectCountry[country]
        );
      }
    });
  },
  ...tableMutations
};
